<template>
  <div>
    <breadcrumb name="A+" title="Advance Plus" subtitle="Edit A+ Video"/>
    <div class="body">
      <div>
        <label for="type">Choose Type</label>

        <multiselect id="type" v-model="type" style="height: 40px; margin-top: 4px;" :options="typeOptions" :close-on-select="true"
                     :clear-on-select="true" :multiple="false"/>
      </div>
      <div v-if="typeValue === '2'">
        <div>
          <vs-row class="d-flex">
            <vs-col vs-lg="12" vs-sm="12">
              <label for="link">Insert Video Link</label>
              <vs-input v-model="link" class="input" id="link" style="width: 45%"/>
            </vs-col>
<!--            <vs-col vs-lg="12" vs-sm="12">-->
<!--              <div>-->
<!--                <iframe class="img-uploader" :src="optimizedLink + '?mute=1&autoplay=1'"-->
<!--                        v-if="link.includes('youtube') || link.includes('youtu.be')"/>-->
<!--              </div>-->
<!--            </vs-col>-->
          </vs-row>
        </div>
      </div>
      <div v-if="typeValue === '1'" style="display: flex; flex-direction: row;">

        <img-upload :images="videos" title="Upload Video" @image_uploaded="saveVideo" :key="videoCounter"
                    :single="true" accept="video/*"/>

        <img-upload :images="images" title="Upload Thumbnail" @image_uploaded="saveImage" :key="imagesCounter"
                    :single="true"/>
      </div>
      <div class="flex">
        <vs-button class="ml-5 cancel" type="filled" color="primary" icon="save" :disabled="invalidForm"
                   @click="confirmSaveData">
          Save
        </vs-button>
        <vs-button class="ml-5 cancel" type="border" color="primary">
          Cancel
        </vs-button>
      </div>
      <div class="clr"/>
    </div>
  </div>
</template>

<script>
  import ImgUpload from '../../../components/general/img-upload';
  import {ChevronsRightIcon, HomeIcon} from 'vue-feather-icons';
  import Breadcrumb from "../../../components/general/breadcrumb";
  import Multiselect from "vue-multiselect";

  export default {
    name: 'add-new-gallery',
    components: {Breadcrumb, ImgUpload, HomeIcon, ChevronsRightIcon, Multiselect},
    data() {
      return {
        baseImagesURL: 'http://mansour-dev.s3.us-east-2.amazonaws.com/',
        name: '',
        images: [],
        videos: [],
        item: {},
        thumbnail: '',
        imagesCounter: 0,
        videoCounter: 0,
        flags: {
          name: false,
          images: true
        },
        link: '',
        type:'Upload Video',
        typeValue: '1',
        typeOptions: ["Upload Video", "YouTube Link"],
      };
    },
    methods: {
      getData() {
        this.$vs.loading();
        this.$httpAdmin.get('config/by-key/aplus_video')
          .then(r => {
            //console.log(r)
            this.item = r.data.data;

            if (this.item.value.includes('youtube') || this.item.value.includes('youtu.be')) {
              this.type = this.typeOptions[1];
              this.typeValue = '2';
              this.link = this.item.value;
              this.$vs.loading.close();

            } else {

              this.type = this.typeOptions[0];
              this.typeValue = '1';
              this.videos = [this.item.value];
              this.videoCounter++;
            }

            this.$httpAdmin.get(`config/by-key/aplus_video_thumbnail`)
              .then(r => {
                //console.log(r)
                this.thumbnail = r.data.data;
                if (this.thumbnail.value) {
                  this.images = [this.baseImagesURL + this.thumbnail.value];
                  this.imagesCounter++;
                }
                this.$vs.loading.close();
              })
              .catch(() => {
                //console.log(e);
                this.$vs.notify({
                  title: 'Error',
                  text: "Couldn't get Data from server!",
                  color: 'danger'
                })
              })
          }).catch(() => {
          this.$vs.loading.close();
          //console.log(e);
          this.$vs.notify({
            title: 'Error',
            text: "Couldn't get Data from server!",
            color: 'danger'
          })
        })
      },
      saveData() {

        this.$vs.loading();

        let formData = new FormData();
        let formData2 = new FormData();

        let images = [];

        for (let i in this.images) {
          let imageURL = this.images[i].split(this.baseImagesURL).pop();
          images.push(imageURL);
        }

        if (this.typeValue === '1') {
          formData.append('value', this.videos.toString());
          formData2.append('value', images.toString());
        }

        if (this.typeValue === '2') {
          formData.append('value', this.link);
          formData2.append('value', '');
        }

        this.$httpAdmin.post(`config/update/${this.item.id}`, formData)
          .then(() => {
            //console.log(r);

            this.$httpAdmin.post(`config/update/${this.thumbnail.id}`, formData2)
              .then(() => {
                //console.log(r);
                this.$vs.loading.close();
                if (this.typeValue === '1') {
                  this.$vs.notify({
                    title: 'Success',
                    text: 'Video & Thumbnail are saved successfully!',
                    color: 'success'
                  });
                }
                else if (this.typeValue === '2') {
                  this.$vs.notify({
                    title: 'Success',
                    text: 'YouTube Link is saved successfully!',
                    color: 'success'
                  });
                }
              })
              .catch(() => {
                this.$vs.loading.close();
                //console.log(e);
                this.$vs.notify({
                  title: 'Error',
                  text: "Couldn't Save data!",
                  color: 'danger'
                })
              })
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: "Couldn't Save data!",
              color: 'danger'
            })
          })
      },
      saveImage(imagesArray) {
        this.images = imagesArray;
        //console.log(this.images);
        this.imagesCounter++;
      },
      saveVideo(videoArray) {
        this.videos = videoArray;
        //console.log(this.images);
        this.imagesCounter++;
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: 'Confirm',
          text: 'You are going to save these data!',
          color: 'primary',
          accept: () => this.saveData(),
          type: 'confirm'
        });
      },
      successUpload(e) {
        //console.log(e);
        let imageURL = this.baseImagesURL + JSON.parse(e.target.response).data;
        this.images.push(imageURL);
      },
      failUpload() {
        this.$vs.notify({
          title: 'Error',
          text: 'Failed to Upload Image!',
          color: 'danger'
        });
      },
      confirm_delete_image(index) {
        this.$vs.dialog({
          type: 'confirm',
          accept: () => this.delete_image(index),
          color: 'danger',
          title: 'Delete Image',
          text: 'You are going to delete this image'
        });
      },
      delete_image(index) {
        this.images.splice(index, 1);
      },
    },
    computed: {
      invalidForm() {
        if (this.typeValue === '1' && this.videos.length !== 0 && this.images.length != 0) {
          return false;
        } else if (this.typeValue === '2' && this.link && this.link.includes('https://www.youtube.com')) {
          return false;
        }
        return true;
      },
      optimizedLink() {
        let index = this.link.lastIndexOf('=');

        if (index === -1) {
          return this.link;
        }

        let url = this.link.substr(index + 1);

        //console.log({url: url})
        return 'https://www.youtube.com/embed/' + url;
      }
    },
    watch: {
      name(v) {
        v === '' ? this.flags.name = true : this.flags.name = false;
      },
      images(v) {
        v.length === 0 ? this.flags.images = true : this.flags.images = false;
      },
      type(v) {
        if(v === this.typeOptions[0])
        {
          this.typeValue = '1'
        }else{
          this.typeValue = '2'
        }
      },
    },
    mounted() {
      this.getData();
    }
  }
  ;
</script>

<style scoped lang="scss">
  @import "../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 50%;

  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    /*max-width: 100%;*/
    width: 200px;
    /*border-radius: 20px;*/
    /*padding: 10px*/
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }

  .flex {
    display: flex;
    justify-content: flex-end;
  }

  .d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }


  label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
  }

  .multiselect {
    width: 45%;
  }

  .img-uploader {
    margin-top: 20px;
  }
</style>
